$(document).ready(() => {
    $(".modal-open").click((e) => {
        let formType = $(e.currentTarget).data("title");
        $("input#formType").val(formType);
        $(".modal-product-name").html(formType);
        $(".modal-form__window--type").html(formType);
        e.preventDefault();
        return false;
    });
});


// Отправка данных на сервер
function send(event, php) {
	console.log("Отправка запроса");
	event.preventDefault ? event.preventDefault() : event.returnValue = false;
	var req = new XMLHttpRequest();
	req.open('POST', php, true);
	req.onload = function() {
		if (req.status >= 200 && req.status < 400) {
			Swal.fire({
                icon: 'success',
                title: 'Заявка отправлена',
                text: 'Мы свяжемся с Вами в ближайшее время.',
			});
			$('.modal-form').removeClass('modal-form__active');
			$('#callbackForm').each((_, form) => form.reset());
		} else {
            Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                text: 'Что-то пошло не так, данные не были отправлены. Повторите попытку или свяжитесь с нами по телефону +7 (495) 12-02-120',
            });
			$('.modal-form').removeClass('modal-form__active');
			$('#callbackForm').each((_, form) => form.reset());
        }}; 
	
	// Если не удалось отправить запрос. Стоит блок на хостинге
	req.onerror = function() {alert("Ошибка отправки запроса");};
	req.send(new FormData(event.target));
}