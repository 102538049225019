$(function(){
    $('.footer-contacts__map-btn').click(function(){
        $('.footer-contacts__modal-map').toggleClass('footer-contacts__modal-map_active');
    });

    $('.footer-contacts__modal-map--close').click(function(){
        $('.footer-contacts__modal-map').removeClass('footer-contacts__modal-map_active');
    });

    $(document).mouseup(function (e) {
        var container = $(".footer-contacts__modal-map--content");

        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $('.footer-contacts__modal-map').removeClass('footer-contacts__modal-map_active');
        };
    });

});