$('.products__item-more').fancybox({
	touch: false
});

$('a.products__item-name').fancybox({
	touch: false
});

tippy('.products__item-bw', {
	content: "Белая база",
});

tippy('.products__item-bc', {
	content: "Прозрачная база под колеровку",
});