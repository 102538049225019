$(function(){
    $('.modal-open').click(function(){
        $('.modal-form').toggleClass('modal-form__active');
    });

    $('.modal-form__window--close').click(function(){
        $('.modal-form').removeClass('modal-form__active');
    });

    $(document).mouseup(function (e) {
        var container = $(".modal-form__window");

        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $('.modal-form').removeClass('modal-form__active');
        };
    });

});